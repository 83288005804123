import React, { Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";  // Use Routes instead of Switch
import Header from "./components/Header";
import Footer from "./components/Footer";
import './styles/styles.css';


// טוען את הדפים בצורה דינמית (lazy loading)
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const OurTeam = React.lazy(() => import("./pages/OurTeam"));
const OurStars = React.lazy(() => import("./pages/OurStars"));
const OurVideos = React.lazy(() => import("./pages/OurVideos"));

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // שינוי מצב ה-Loading לאחר זמן מסוים כדי להפסיק את האנימציה
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // הגדרת זמן טעינה של 1 שניה

    return () => clearTimeout(timer);
  }, []);

return (
  <Router>
    <div className="App">
      <Header />
      <div className="content">
        {/* ה-Suspense צריך לעטוף את כל התוכן */}
        <Suspense fallback={<div className="loading-animation">טוען...</div>}>
          <AboutUs />
          <OurTeam />
          <OurStars />
          <OurVideos />
        </Suspense>
      </div>
      <Footer />
    </div>
  </Router>
);
}

export default App;
