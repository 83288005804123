import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="floating-footer">
      <ul>
        <li><a href="#page-1" className="nav-item">מי אנחנו</a></li>
        <li><a href="#page-2" className="nav-item">הצוות שלנו</a></li>
        <li><a href="#page-3" className="nav-item">הכוכבים שלנו</a></li>
        <li><a href="#page-4" className="nav-item">VOD</a></li>
      </ul>
    </footer>
  );
}

export default Footer;
